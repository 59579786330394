<template>
  <danchuang v-show="user.xianshi" :user="user" @xianshi="qqq" />
  <xiaoxifabu
    v-show="out.xiaoxifabu1"
    :out="out"
    @fabu="getxianshi"
  ></xiaoxifabu>
  <div>
    <div class="top">
      <div class="top-con">
        <div class="left">
          <a>石家庄</a>
        </div>
        <div class="right">
          <div class="con1" v-if="this.name != undefined">
            欢迎{{ this.name }}
          </div>
          <div class="con1" v-if="this.name == undefined">
            <router-link to="/Denglu">登录</router-link>
            /
            <router-link to="/zhuce">注册</router-link>
          </div>
          <div class="con2">
            个人中心
            <img src="../img/xx.png" alt="" width="15px" />
            <div class="xl-con">
              <a @click="getgeren"
                >个人中心
                <img src="../img/xs.png" alt="" width="15px" />
              </a>
              <a>我的账户</a>
              <a>我的求职</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sousuo">
      <div class="header">
        <img id="bigLogo" src="../img/logo-i.png" style="width: 60px" />
        <span class="logo">聚信人和</span>
        <a class="postbtn" @click="xiaoxifabu">信息发布</a>
      </div>
    </div>
    <div class="basicinfo clearfix" id="basicinfo">
      <div class="detail-title">
        <div class="detail-title_info">
          <div class="detail-title_info_text">2021-11-02更新</div>
          <div class="detail-title_info_text"><span>24759</span>次浏览</div>
          <div class="detail-title_info_text">活动刷新：2021-11-08</div>
        </div>
      </div>
      <div class="switch switch_undecisionParams">
        <ul
          class="switch__big-img _main_img"
          v-for="item in list"
          :key="item.id"
        >
          <li>
            <a class="zhiwei">{{ item.title }}</a>
          </li>
        </ul>
      </div>
      <div class="infocard__container haveswitch">
        <div class="infocard__container__item">
          <div class="infocard__container__item__title">
            薪&nbsp;&nbsp;&nbsp;&nbsp;资:
          </div>
          <div
            class="infocard__container__item__main"
            v-for="item in list"
            :key="item.id"
          >
            <span class="infocard__container__item__main__text--price">
              {{ item.salaryS }}-{{ item.salaryE }}
            </span>
          </div>
        </div>
        <p
          style="color: #666; font-size: 14px"
          v-for="item in list"
          :key="item.id"
        >
          联系人：&nbsp;&nbsp;&nbsp;&nbsp;{{ item.user }}
        </p>
        <p
          style="color: #666; font-size: 14px"
          v-for="item in list"
          :key="item.id"
        >
          联系电话：{{ item.phone }}
        </p>
        <div
          class="infocard__container__item"
          @click.prevent="user.xianshi = !user.xianshi"
          v-show="!user.cg"
        >
          <a href="" class="btn_tocompletetel flag-first" id="view-connect"
            ><span>申请职位</span>
          </a>
        </div>
        <router-link to="/">
          <div class="infocard__container__item">
            <span class="btn_tocompletetel">返回首页</span>
          </div>
        </router-link>
        <div
          class="infocard__container__item"
          @click.prevent="to = '/baojie1'"
          v-show="user.cg"
        >
          <a href="" class="btn_tocompletetel1 flag-first" id="view-connect"
            ><span>申请成功</span>
          </a>
        </div>
      </div>
      <div class="clearfix" id="contentleft">
        <div class="detail-desc__bar" id="detail_tab">
          <a href="">
            <span>职位描述</span>
          </a>
        </div>
        <div class="detail-desc bc" style="margin-top: 56px">
          <div class="detail-desc__content__desc description">
            <div class="" id="phoneFrame_con">
              <div class="descriptionBox detail-desc__content__desc__box">
                <div class="foldingbox">
                  <article class="description_con">
                    <p v-for="item in list" :key="item.id">
                      <span style="font-size: 16px">{{ item.content }}</span>
                    </p>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="subitem_con comp_intro">
          <h2 class="title">公司简介</h2>
          <div class="photos">
            <div class="pic">
              <!-- <ul class="picList">
                <li>
                  <img src="../img/baojie1.jpg" alt="" />
                </li>
                <li>
                  <img src="../img/gszp.png" alt="" />
                </li>
                <li>
                  <img src="../img/gszp.png" alt="" />
                </li>
                <li>
                  <img src="../img/gszp.png" alt="" />
                </li>
              </ul> -->
            </div>
          </div>
        </div>
        <div class="txt">
          <div class="comIntro" style="height: 300px">
            <div class="intro">
              <div class="shiji" v-for="item in list" :key="item.id">
                <p>{{ item.company }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div>
        <a
          target="_blank"
          href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
          rel="nofollow"
          >冀ICP备20010751号</a
        ><span>|</span
        ><a
          target="_blank"
          href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
          rel="nofollow"
          >冀ICP备20010751号-2</a
        ><span>|</span
        ><a
          target="_blank"
          href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
          rel="nofollow"
          >津文执网文【2019】</a
        ><span>|</span
        ><a
          target="_blank"
          href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
          rel="nofollow"
          >河北坤洲网络科技有限公司</a
        ><span>|</span
        ><a
          target="_blank"
          href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
          >联系我们</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import danchuang from "../components/danchuang.vue";
import axios from "axios";
import Cookies from "js-cookie";
import xiaoxifabu from "../components/xiaoxifabu.vue";

export default {
  components: { danchuang, xiaoxifabu },
  data() {
    return {
      list: [],
      user: { xianshi: false, cg: false },
      id: "",
      name: "",
      out: { xiaoxifabu1: false, token: "" },
    };
  },
  created() {
    this.id = this.$route.params.id;
    console.log("为什么没有啊啊啊阿 ", this.id);
  },
  mounted() {
    this.huoquxiangqing();
    this.name = Cookies.get("phone");
  },
  methods: {
    xiaoxifabu() {
      if (this.name != undefined) {
        this.out.xiaoxifabu1 = true;
      } else {
        alert("请先登录");
        this.$router.push("./Denglu");
      }
    },
    getxianshi(e) {
      this.out.xiaoxifabu1 = e;
    },
    qqq(ev) {
      (this.user.xianshi = ev[0]), (this.user.cg = ev[1]);
    },
    huoquxiangqing() {
      axios
        .get(
          "http://123.56.43.64:15001/message/selectById/" +
            // "http://192.168.28.37:15001/message/selectById/"
            this.id
        )
        .then((res) => {
          this.list.push(res.data.data);
          console.log("阿速达哈苏1111", this.list);
        });
    },
  },
};
</script>

<style>
.logo {
  display: flex;
  font-size: 20px;
  font-weight: 800;
  margin-top: -45px;
  margin-left: 170px;
  color: rgba(177, 171, 171, 0.966);
}
.xl-con a {
  width: 100%;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #555;
  white-space: nowrap;
}

.xl-con a:first-child {
  color: #ff552e;
}

.con2 img {
  width: 9px;
  margin-left: 9px;
}

.con2:hover .xl-con {
  display: block;
}

.xl-con {
  position: absolute;
  top: -1px;
  border: 1px solid #ddd;
  padding: 0 10px;
  display: none;
  z-index: 99;
  background-color: #fff;
}

.con2 {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  margin-left: 10px;
  padding: 0 10px;
}

.con1 a {
  color: #555;
  margin: 0 2px;
}

.left {
  color: red;
  margin-left: 100px;
}

.right {
  display: flex;
  height: 100%;
  align-items: center;
}

.top {
  width: 100%;
  height: 35px;
  line-height: 35px;
  background: #fff;
  color: #555;
  border-bottom: 1px solid #ddd;
}
a:hover {
  color: #ff552e;
}
.top-con {
  width: 1190px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

/* 这个算通用样式，初始化 */
body {
  font-size: 12px;
  margin: 0;
  color: rgb(51, 51, 51);
  min-width: 1190px;
}

button {
  cursor: pointer;
}
img {
  vertical-align: middle;
  border: 0;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  cursor: pointer;
}
.sousuo {
  height: 80px;
  width: 1200px;
  margin: 0 auto;
  background-color: #fff;
}

#bigLogo {
  display: inline-block;
  margin: 10px 0 0 100px;
}
.postbtn {
  width: 100px;
  height: 90px;
  color: #ff552e;
  background: #ffefe7;
  line-height: 36px;
  text-align: center;
  font-size: 20px;
  margin-left: 1000px;
  position: relative;
  padding: 5px;
  top: -30px;
}
.header {
  overflow: hidden;
  margin: 0 auto;
  width: 1190px;
  padding: 20px 0 12px;
  position: relative;
  z-index: 10;
}
#basicinfo {
  width: 1190px;
  padding: 0;
  margin: 0 auto;
  margin-top: 70px;
  background: #fff;
}
.clearfix {
  display: block;
}
.detail-title {
  position: relative;
  z-index: 99;
  padding: 0 30px;
  border-bottom: 1px solid #eee;
  background: #fff;
}
.detail-title_name {
  width: 900px;
  font: 400 20px/30px "微软雅黑", sans-serif;
  color: #333;
  padding: 14px 0 0;
}
.detail-title_info {
  margin: 7px auto 0;
  line-height: 20px;
  height: 20px;
  z-index: 21;
  padding: 0 0 12px;
  overflow: hidden;
}
.detail-title_info_text {
  float: left;
  margin-right: 10px;
  font-size: 14px;
  line-height: 20px;
  color: #999;
}
.switch {
  float: left;
  width: 340px;
  overflow: hidden;
  padding: 0;
  padding: 30px 0 40px 30px;
  margin-top: 50px;
  margin-left: 50px;
}
.switch li,
.switch ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.haveswitch {
  width: 480px;
  margin-top: 30px;
  padding-left: 20px;
  min-height: 341px;
}
.infocard__container {
  float: left;
  display: inline;
  padding: 30px 0 20px 40px;
  min-height: 262px;
}
.infocard__container__item {
  clear: both;
  font-size: 14px;
  padding: 3px 0;
  list-style: none;
  line-height: 31px;
  margin-right: -300px;
}
div {
  font-family: "Hiragino Sans GB", "Microsoft YaHei", 微软雅黑, arial, Tahoma,
    SimSun, sans-serif;
}
.infocard__container__item__title {
  width: 72px;
  float: left;
  color: #666;
  text-align: left;
  padding-right: 10px;
  overflow: hidden;
}
.zhiwei {
  display: inline-block;
  margin-left: 90px;
  font-weight: 600;
  font-size: 30px;
  color: #333;
}
.zhiwei1 {
  display: inline-block;
  margin-left: 95px;
  margin-top: 20px;
  color: #000;
  font-size: 16px;
}
.haveswitch .infocard__container__item__main {
  width: 398px;
}
.infocard__container__item__main {
  display: inline-block;
  float: left;
  width: 750px;
  color: #333;
}
.infocard__container__item__main__text--price {
  font-size: 16px;
  color: #f50;
  vertical-align: middle;
  margin-right: 5px;
}
.infocard__container__item {
  clear: both;
  font-size: 14px;
  padding: 3px 0;
  list-style: none;
  line-height: 31px;
  margin-right: -300px;
}
#view-connect {
  width: 264px;
  float: left;
  margin-right: 5px;
  height: 58px;
  line-height: 58px;
}
a.btn_tocompletetel {
  display: block;
  width: 280px;
  height: 54px;
  line-height: 54px;
  text-align: center;
  overflow: hidden;
  color: #fff;
  background: #ff552e;
  font-size: 20px;
  cursor: pointer;
}
.btn_tocompletetel {
  display: block;
  width: 265px;
  height: 54px;
  line-height: 54px;
  text-align: center;
  overflow: hidden;
  color: #fff;
  background: #ff552e;
  font-size: 20px;
  cursor: pointer;
}
a.btn_tocompletetel1 {
  display: block;
  width: 280px;
  height: 54px;
  line-height: 54px;
  text-align: center;
  overflow: hidden;
  color: #fff;
  background: #a1a1a1;
  font-size: 20px;
  cursor: pointer;
}
.contentleft {
  width: 922px;
  float: left;
  margin-right: 10px;
  margin-top: 20px;
}
.detail-desc__bar {
  width: 1190px;
  height: 56px;
  padding-left: 5px;
  clear: both;
  background: #fff;
  border-bottom: 1px solid #eee;
  z-index: 1002;
}
.detail-desc__bar a {
  text-align: center;
  display: block;
  height: 56px;
  line-height: 56px;
  float: left;
  color: #666;
  text-decoration: none;
  cursor: pointer;
  font-size: 18px;
  padding: 0 25px;
}
#pjxq {
  color: #ff552e;
}
.detail-desc {
  font-size: 14px;
  background: #fff;
}
.detail-desc__content__desc {
  clear: both;
  margin-top: 20px;
  font-size: 14px;
}
.detail-desc__content__desc__box {
  margin: 20 px auto;
  clear: both;
  line-height: 200%;
  padding: 0 30px;
  padding-bottom: 30px;
}
.foldingbox {
  font-family: "Hiragino Sans GB", "Microsoft YaHei", 微软雅黑, arial, Tahoma,
    SimSun, sans-serif;
  font-style: inherit;
  font-weight: inherit;
  vertical-align: baseline;
  border-width: 0;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  margin: 0;
  outline: 0;
  padding: 0;
}
.descriptionBox {
  margin: 20px auto;
  line-height: 200%;
  padding: 0 30px;
}
article {
  display: block;
}
.con .leftCon .item_con .subitem_con {
  margin-bottom: 10px;
}
.comp_intro .title,
.comp_intro_daipei .title {
  font-size: 20px;
  color: #333;
  font-weight: 600;
  margin-bottom: 40px;
}
.comp_intro .photos .pic {
  margin-bottom: 32px;
  overflow: hidden;
  position: relative;
}
.pic li {
  display: block;
  float: left;
  margin-left: 25px;
}
ul {
  list-style: none;
}
.txt {
  overflow: hidden;
}
.txt {
  line-height: 30px;
  font-size: 14px;
  color: #2e343b;
  margin: 0 0 32px;

  overflow: hidden;
}
.bottom {
  overflow: hidden;
  width: 100%;
  height: 150px;
  /* background-color: red; */
  /* border: 1px solid black; */
  margin: 0 auto;
  line-height: 16px;
  text-align: center;
  color: #999;
}
.bottom div {
  height: 50px;
  line-height: 50px;
  width: 100%;
  margin-top: 90px;
  border-top: 1px solid #999;
}
.bottom div span {
  margin: 0 10px;
  color: #eee;
}

.bottom div a {
  color: #999;
  font-size: 13px;
  /* margin-left: 20px; */
}
</style>
