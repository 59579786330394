<template>
  <div class="abtestA" id="basicPage">
    <div class="basic-info">
      <form action="" class="basic-form" id="basicForm">
        <div class="info-item">
          <div class="item-title">
            <span class="title1">姓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名：</span>
          </div>
          <input
            type="text"
            maxlength="4"
            placeholder="请输入2-4个汉字"
            class="input width100"
          />
        </div>
        <div class="info-item">
          <div class="item-title">
            <span class="title1">性&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;别：</span>
          </div>
          <div class="sex">
            <input type="radio" name="sex" />男
            <input type="radio" name="sex" />女
          </div>
        </div>
        <div class="info-item">
          <div class="item-title">
            <span class="title1" style="width: 80px">出生年月：</span>
          </div>
          <select name="" id="" class="input width100">
            <option value="">请选择年份</option>
            <option value="2000年">2000年</option>
            <option value="1999年">1999年</option>
            <option value="1998年">1998年</option>
            <option value="1997年">1997年</option>
            <option value="1996年">1996年</option>
            <option value="1995年">1995年</option>
          </select>
        </div>
        <div class="info-item">
          <div class="item-title">
            <span class="title1" style="width: 80px">最高学历：</span>
          </div>
          <select name="" id="" class="input width100">
            <option value="">请选择最高学历</option>
            <option value="">高中</option>
            <option value="">中专/技校</option>
            <option value="">大专</option>
            <option value="">本科</option>
            <option value="">硕士</option>
            <option value="">博士</option>
          </select>
        </div>
        <div class="info-item">
          <div class="item-title">
            <span class="title1">手&nbsp;&nbsp;机&nbsp;&nbsp;号：</span>
          </div>
          <input
            type="text"
            maxlength="11"
            placeholder="请输入手机号"
            class="input width100"
            v-model.number="phone"
          />
        </div>
        <div
          class="toudi"
          @click.prevent="$emit('xianshi', [!user.xianshi, !user.cg])"
        >
          投递
        </div>
        <div class="quxiao" @click.prevent="$emit('xianshi', !user.xianshi)">
          取消
        </div>
      </form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return { phone: "" };
  },
  // props:["modelValue"],
  props: ["user"],

  mounted() {
    console.log("dayin", this.user);
  },
};
</script>

<style>
div {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}
#basicPage {
  width: 100%;
  height: 100%;
}

#basicPage.abtestA .basic-info {
  border: 1px solid #ddd;
  background-color: rgb(255, 253, 253);
  margin: 200px 0 0 650px;
  position: absolute;
  padding: 30px 0;
  max-height: 531px;
  overflow-y: auto;
}
#basicPage .basic-form {
  width: 585px;
  display: inline-block;
}
#basicPage.abtestA .info-item {
  margin-bottom: 14px;
}
#basicPage .item-title {
  width: 90px;
  float: left;
  line-height: 40px;
}
.item-title {
  display: inline-block;
  margin-right: 12px;
  font-weight: 700;
}
#basicPage.abtestA .input {
  height: 40px;
  line-height: 40px;
  padding: 0 12px;
  margin-left: 30px;
}
.input {
  font-size: 14px;
  color: #333;
  border: 1px solid #ddd;
}
.title1 {
  display: inline-block;
  color: #666;
  margin-left: 35px;
  font-size: 16px;
}
#basicPage .width100 {
  width: 340px;
}
.sex {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 12px;
  margin-left: 20px;
}
.submit {
  position: fixed;
  width: 100px;
  height: 40px;
  top: 90px;
  left: 600px;
}
.quxiao {
  width: 100px;
  height: 40px;
  cursor: pointer;
  background-color: #ccc;
  color: rgb(255, 255, 255);
  text-align: center;
  line-height: 40px;
  display: inline-block;
  position: relative;
  top: 20px;
  left: 50px;
}
.toudi {
  display: inline-block;
  width: 100px;
  height: 40px;
  background-color: #ccc;
  color: rgb(255, 255, 255);
  cursor: pointer;
  background-color: #ff552e;
  text-align: center;
  line-height: 40px;
  position: relative;
  top: 20px;
  left: 300px;
}
.submit1 {
  position: fixed;
  width: 100px;
  height: 40px;
  top: 90px;
  left: 600px;
}

#basicPage .submit-btn {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  text-align: center;
  width: 100px;
}
</style>